import axios from 'axios'
import wcs from './index'
import axiosInstance from "../../../axios-mocks/mock-api/axiosMockApi"
const { storeId, langId, catalogId } = wcs.getStoreConfig()

import { isLocalHost } from "../utils/url"
export const getProductAvailability = async (productId: string) => {
  const { storeId } = wcs.getStoreConfig()
  const origin = window.location.origin
  const url = `${origin}/wcs/resources/store/${storeId}/inventoryavailability/${productId}`
  const res = await axios.get(url)
  return res.data.InventoryAvailability[0].availableQuantity
}

export const setSizeActive = (element: any) => {
  const sizeList = window.productDataSize
  sizeList.forEach((sizeElement: any) => {
    if (sizeElement.currentSkuID == element.currentSkuID) {
      sizeElement.activeSizeClass = 'pdp-slide-menu-filter-list__item--active'
    } else {
      sizeElement.activeSizeClass = ''
    }
  })
}

export const loadProductSizes = async (productId: string) => {
  const { storeId } = wcs.getStoreConfig()
  const origin: string = window.location.origin
  const mockUrl: string = 'mock-api/typescript/product/variants-size'
  const liveUrl: string = `${origin}/wcs/resources/store/${storeId}/products/${productId}/variants/size?langId=${langId}`
  const url: string = isLocalHost() ? mockUrl : liveUrl
  const axiosInitiator = isLocalHost() ? axiosInstance : axios
  const response = await axiosInitiator.get(url)
  return response.data?.result?.sizes || response.data || []
}

export const addToBagPdp = async (isInWishlist = false, productId: string, hasEngraving: boolean = false, isAvailableQuantityMoreThanZero: boolean = true, quantity: number = 1, shippingId: string = '') => {
  const checkInventoryAvailability = isAvailableQuantityMoreThanZero ? 'Y' : 'N'
  const requestUrl: string = '/webapp/wcs/stores/servlet/AjaxOrderChangeServiceItemAdd'
  const formDataAddToBag: any = new FormData()
  let isEditedProduct: boolean = false
  formDataAddToBag.append('storeId', storeId)
  formDataAddToBag.append('langId', langId)
  formDataAddToBag.append('orderId', '.')
  formDataAddToBag.append('calculationUsage', '-1,-2,-3,-4,-5,-6,-7')
  formDataAddToBag.append('catalogAssortment', 'SGH')
  formDataAddToBag.append('PromoFromCookie', '')
  formDataAddToBag.append('srtoken', 'false')
  formDataAddToBag.append('page', 'pdp')
  formDataAddToBag.append('catalogOrigin', '')
  formDataAddToBag.append('catEntryId', productId)
  formDataAddToBag.append('quantity', quantity)
  formDataAddToBag.append('doInventory', checkInventoryAvailability)
  try {
    const searchParams = new URLSearchParams(window.location.search)
    const isEngravingEdited: boolean = searchParams.has('engravedFlag')
    const cartOrderItemId: string = searchParams.get('orderItem') || ''

    //this call for apply engraving to order
    if(hasEngraving) {
      if (isEngravingEdited && cartOrderItemId) {
        isEditedProduct = true
        await addEngravingInCart(cartOrderItemId)
      }
    }

    //remove product from cart if you return from checkout but have no engraving
    if(sessionStorage.getItem('engravingDeleted')) {
      isEditedProduct = true
      await deleteEngravingInCart(cartOrderItemId)
    }
    
    if (!isEditedProduct && !isInWishlist) {
      const response = await axios.post(requestUrl, formDataAddToBag);
      const orderItemId = response.data.orderItemId;
      if (hasEngraving) {
        await addEngravingInCart(orderItemId)
      }
      localStorage.setItem('SGH_ORDERID', response.data.orderId[0]);
      await addToBagPdpDataCalculate(orderItemId); //TODO: check if maybe we can avoid await for this call
    }

    if(!isAvailableQuantityMoreThanZero && shippingId) {
      OrderItemAddressShipMethodUpdate(shippingId)
    }

    const timeStamp = new Date().getTime();
    const minicartResponse = await axios.get(`/wcs/resources/store/${storeId}/cart/sgh/@self?catalogId=${catalogId}&langId=${langId}&storeId=${storeId}&_=${timeStamp}`);
    return minicartResponse.data
  } catch (error) {
    console.error('Error adding to bag:', error);
    throw error;
  }
}

async function addToBagPdpDataCalculate(orderItemId: string) {
  const requestUrl: string = '/webapp/wcs/stores/servlet/AjaxOrderCalculate'
  const formDataCalculate: any = new FormData()
  formDataCalculate.append('storeId', storeId)
  formDataCalculate.append('langId', langId)
  formDataCalculate.append('updatePrices', '1')
  formDataCalculate.append('orderId[]', orderItemId)
  formDataCalculate.append('calculationUsage', '-1,-2,-3,-4,-5,-6,-7')
  return axios.post(requestUrl, formDataCalculate)
}

export const getEngravingData = async (id: string, urlIndex = 1) => {
  const { storeId } = wcs.getStoreConfig()
  let resp = null
  if (isLocalHost()) {
    resp = (await axiosInstance.get('mock-api/typescript/engravind-data')).data.result
  } else {
    const url =
      urlIndex === 1
        ? `/wcs/resources/store/${storeId}/engraving/temp/${id}`
        : `/wcs/resources/store/${storeId}/engraving/order-item/${id}`
    try {
      resp = (await axios.get(url)).data
    } catch (error) {
      throw new Error(
        'Error on retrieving engraving data with function getEngravingData',
        { cause: error instanceof Error ? error : undefined }
      )
    }
  }
  return resp
}

export const applyEngraving = (wholeObj: any, data: any) => {
  const { storeId } = wcs.getStoreConfig()
  const urlPath = `/wcs/resources/store/${storeId}/engraving/tmp_write/@self?time=${new Date().getTime()}`
  const engraving = {
    ...wholeObj,
    positions: data,
  }
  const serializedObj = JSON.stringify(engraving)

  const res = axios.post(urlPath, serializedObj, { headers: { 'Content-Type': 'application/json' } })
  return res
}



export const addEngravingInCart = async (orderItemId: string) => {
  const { storeId } = wcs.getStoreConfig()
  const restEngravingEndPoint = `/wcs/resources/store/${storeId}/engraving/write/${orderItemId}`
  const dataFromStorage = sessionStorage.getItem('engravingInfo') || ''
  const engravindData = JSON.parse(dataFromStorage)?.engravingData?.allData?.engravingJson

  await axios.post(restEngravingEndPoint, JSON.stringify(engravindData), { headers: { 'Content-Type': 'application/json' } })
}
export const deleteEngravingInCart = async (orderItemId: string) => {
  const { storeId } = wcs.getStoreConfig()
  const restEngravingEndPoint = `/wcs/resources/store/${storeId}/engraving/delete/${orderItemId}`
  sessionStorage.removeItem('engravingInfo')
  sessionStorage.removeItem('engravingDeleted')
  await axios.post(restEngravingEndPoint, { headers: { 'Content-Type': 'application/json' } })
}
export const OrderItemAddressShipMethodUpdate = async (shipModeId: string) => {
  const serviceURL = '/webapp/wcs/stores/servlet/AjaxOrderChangeServiceShipInfoUpdate'
  const { storeId, langId, catalogId } = wcs.getStoreConfig()
  const formDataCalculate: any = new FormData()
  formDataCalculate.append('storeId', storeId)
  formDataCalculate.append('langId', langId)
  formDataCalculate.append('catalogId', catalogId)
  formDataCalculate.append('orderId', '.')
  formDataCalculate.append('calculationUsage', '-1,-2,-3,-4,-5,-6,-7')
  formDataCalculate.append('allocate', '***')
  formDataCalculate.append('backorder', '***')
  formDataCalculate.append('remerge', '***')
  formDataCalculate.append('check', '*n')
  formDataCalculate.append('doInventory', 'N')
  formDataCalculate.append('shipModeId', shipModeId)
  return axios.post(serviceURL, formDataCalculate)
}

export const AjaxSGHOrderOmniChannelShippingInfo = async (SGHOrderOmniChannelParams: any, redirectUrl: string) => {
  const { orderId, orderType, physicalStoreId, physicalStoreZipcode, storeId} = SGHOrderOmniChannelParams
  const serviceURL = `/webapp/wcs/stores/servlet/AjaxSGHOrderOmniChannelShippingInfo?orderId=${orderId}&orderType=${orderType}&physicalStoreId=${physicalStoreId}&physicalStoreZipcode=${physicalStoreZipcode}&storeId=${storeId}`
  const res = await axios.get(serviceURL)
  localStorage.setItem('choosenCNCShippingData', JSON.stringify(res.data))
  window.location.href = redirectUrl
}
