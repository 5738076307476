import wcs from './../wcs/index'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import get from 'lodash/get'

export function loadExternalLibraries(url, callback) {
  const script = document.createElement('script')
  script.setAttribute('src', url)
  script.setAttribute('type', 'text/javascript')
  document.body.appendChild(script)
  script.addEventListener('load', () => {
    callback()
  })
}

export const setEACookie = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  let url = `${domain}/wcs/resources/store/${storeId}/espot/EarlyAccess/type/STOREFEATURE`
  let data = {}
  await axios
    .get(url)
    .then(response => {
      data = response.data
    })
    .catch(error => {
      console.error('Failed response from EarlyAccess Espot', error)
    })

  const earlyAccessEnabledPath =
    'MarketingSpotData[0].baseMarketingSpotActivityData[0].baseMarketingSpotActivityName'
  const earlyAccessEnabledValue = get(data, earlyAccessEnabledPath, 'false')
  if (earlyAccessEnabledValue === 'true') {
    window.algoliaConfig.isEarlyAccessParam = 'true2'
    VueCookies.set('earlyAccess', 'true2', Infinity)
    return true
  } else {
    VueCookies.remove('earlyAccess')
    return false
  }
}
export const loadExternalLibraryAsync = (options) => {
  const {src, crossOrigin} = options
  return new Promise((resolve,reject) => {
    let newScript = document.createElement('script')
    newScript.setAttribute('src', src)
    newScript.setAttribute("type", "text/javascript")
    newScript.setAttribute("async", 'true')
    if(!!crossOrigin) {
        newScript.setAttribute('crossorigin', 'anonymous')
    }
    document.head.appendChild(newScript)
    newScript.onload = () => {
      resolve(newScript);
    }
    newScript.onerror = reject;
  })
}