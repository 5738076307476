import { loadExternalLibraryAsync } from './utils'

export const initializeRtrScript = async({
  src,
  type = 'master',
  conf
}:{
  src: string,
  type?: string,
  conf: {}
}) => {
  try {
    if (type === 'master') {
      if (!window.rtrViewerMV) {
        await loadExternalLibraryAsync({
          src:src,
          crossOrigin: 'anonymous'
        })
      }
      const rtrViewerMV = window.rtrViewerMV
      !!rtrViewerMV && rtrViewerMV.init(conf)
    } else {
      if (!window.rtrViewer) {
        await loadExternalLibraryAsync({
          src:src,
          crossOrigin: 'anonymous'
        })
      }
      const rtrViewer = window.rtrViewer
      if (rtrViewer?.isInitialized()) {
        rtrViewer?.dispose()
      }
      !!rtrViewer && rtrViewer.init(conf)
    }
  }
  catch
    (error)
    {
      throw new Error('Error initializing RTR', error)
    }
}