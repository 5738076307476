import wcs from './index'
import axios from 'axios'

export const getCMSPlacements = async (parameters, name) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { locale, storeId, langId, catalogId } = wcs.getStoreConfig()
  const userClusterValue = window.algoliaConfig.isEarlyAccessParam ? 'EarlyAccess' : 'None'
  const categoriesParam = parameters?.categories ? `,"categories":"${parameters.categories}"` : ''
  const urlParams = new URLSearchParams(window.location.search)
  const userVariant = urlParams.get('userVariant')
  const previewDate = urlParams.get('previewDate')

  const url = `${domain}/wcs/resources/store/${storeId}/cms/include?exposeError=false&var=${
    parameters?.name ?? ''
  }&storeId=${storeId}&langId=${langId}&catalogId=${catalogId}&placement=${
    parameters?.placement ?? ''
  }&view=${parameters?.view ?? ''}&pageId=${
    parameters?.pageId ?? ''
  }&categoryId=${parameters?.categoryId ?? ''}&productId=${
    parameters?.productId ?? ''
  }&parameter={"USER_CLUSTER":"${userClusterValue}"${categoriesParam}}&locale=${locale}&userVariant=${
    userVariant || ''
  }&previewDate=${previewDate || ''}`

  try {
    const response = await axios.get(url, {
      headers: parameters?.headers || null,
      data: parameters?.axiosDataType || null,
    })
    const data = response.data

    return data
  } catch (error) {
    console.error(
      'Error in getCMSPlacements :',
      error.response ? error.response.data : error.message
    )
    return 'Error'
  }
}
