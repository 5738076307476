import isEmpty from 'lodash/isEmpty'
import find from 'lodash/find'
import orderBy from 'lodash/orderBy'
import mapValues from 'lodash/mapValues'
import unescape from 'lodash/unescape'
import { setSAHingeFilterId } from './utils/const'
import wcs from './wcs/index'
import { getCurrentLocale } from './utils/currentCountry'
import { discountedPriceValue } from './algolia-normalizer'
import { formatPrice, getPropertyByPath } from './utils/string.js'
// imports needed for consuming protobuf response
const protobuf = require('protobufjs')
const root = protobuf.Root.fromJSON(require('./wcs/proto/bundle.json'))
const PBMessage = root.lookupType('Response')
const priceNameConfig = JSON.parse(window.algoliaConfig?.priceName) || {}
//SGH-8760: handling order of filters on FE. here there are the key taken
export const sortedOrderFilters = [
  'gender', // Gender
  'brand', // Brands
  'price', // Price
  'fit', // "Size
  'frameFit', // Fit
  'geoFit', // Bridge choice & nosepads
  'frameShapeFacet', // Frame Shape
  'framematerialFacet', // Frame Material
  'frontColorFacet', // Frame Color
  'lensColorFacet', // Lens Color
  'lensTreatmentFacet', // Lens Type
  'prescription', // Prescription frames
  'specialEdition', // Special Edition => key non confirmed
  'eyeShape', // Frame Type
  'sale', // Sale - key non confirmed
  'faceShape', // Face Shape
  'otherFilters', // Other Filters
]

//https://luxotticaretail.atlassian.net/browse/SGHDP-23179
//some filters require hardcoded options order:
export const filterOptionsOrder = {
  lensTreatmentFacet: [
    'Gradient',
    'Classic',
    'MirrorAndFlash',
    'POLARIZED',
    'Blue Light Lenses',
    'PHOTOCROMATIC',
  ],
  frameShapeFacet: [
    'Pilot',
    'Rectangle',
    'Square',
    'Geometrical',
    'Cat Eye',
    'Round',
    'Phantos',
    'Oval',
    'Butterfly',
    'Shield',
    'Irregular',
  ],
  framematerialFacet: [
    'Metal',
    'Acetate',
    'NylonAndPropionate',
    'Titanium',
    'Premium Materials',
    'Innovative',
    'Other',
  ],
}

let brandName
let suggestionProducts

// function used to normalize the single plp product
export const getProductNormalized = inputItem => {
  let outputItem = {}

  // for polarized button
  outputItem.isPolarized = inputItem.isPolarized || false

  // for the on Sale offer
  if (inputItem.offerPrice) {
    outputItem.price = inputItem.offerPrice || undefined
    outputItem.onSale = {
      promo: inputItem.promo || undefined,
      originalPrice: inputItem.listPrice,
    }
  } else {
    outputItem.price = inputItem.listPrice || undefined
  }
  outputItem.modelName = !inputItem.modelName
    ? ''
    : inputItem.modelName
    ? inputItem.modelName
    : inputItem.mfpartnumber

  outputItem.brand = inputItem.brand || undefined
  outputItem.upc = inputItem.partNumber || undefined
  outputItem.mfpartnumber = inputItem.mfpartnumber || undefined
  outputItem.localizedColorLabel = inputItem.localizedColorLabel || undefined
  outputItem.modelName = inputItem.modelName || undefined
  outputItem.frameColor = inputItem.frameColor || undefined
  outputItem.lensColor = inputItem.lensColor || undefined

  outputItem.badgeBelowModelName = inputItem.badgeBelowLeft || undefined
  outputItem.category = (inputItem.badgeBelowRight && inputItem.badgeBelowRight.label) || undefined
  outputItem.showIconCategory =
    (inputItem.badgeBelowRight && inputItem.badgeBelowRight.iconIdentifier) || false

  outputItem.img = inputItem.img || undefined
  outputItem.imgHover = inputItem.imgHover || undefined
  outputItem.pdpURL = inputItem.pdpURL || '#'
  outputItem.customizeUrl = inputItem.pdpCustomizeUrl || undefined

  outputItem.isEngravable = inputItem.isEngravable || false
  outputItem.isCustomizable = inputItem.isCustomizable || false
  outputItem.isStella = inputItem.isStella || false
  outputItem.isJunior = inputItem.isJunior || false
  outputItem.juniorLabel = inputItem.juniorLabel || undefined
  outputItem.installmentsInfo = inputItem.installmentsInfo || undefined

  outputItem.wishlistRelatedLinks = inputItem.wishlistRelatedLinks || {}

  outputItem.isFindInStore = inputItem.isFindInStore || undefined
  outputItem.isEmailMeWhenAvailable = inputItem.isEmailMeWhenAvailable || undefined
  outputItem.isOutOfStock = inputItem.isOutOfStock

  outputItem.colorsNumber = inputItem.colorsNumber
  outputItem.catentryId = inputItem.catentryId || undefined

  outputItem.isRoxable = inputItem.isRoxable
  outputItem.roxableLabel = inputItem.roxableLabel || undefined

  outputItem.Status = inputItem.status
  outputItem.lifecycle = inputItem.lifecycle
  let isLoyaltyActive = sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
  outputItem.isLoyaltyItem =
    isLoyaltyActive && inputItem.customeType && inputItem.customeType === 'Loyalty'

  if (inputItem.colors && inputItem.colors.length > 0) {
    outputItem.colors = inputItem.colors.map(variantItem => ({
      img: variantItem.img,
      imgHover: variantItem.imgHover,
      price: variantItem.listPrice,
      pdpURL: variantItem.pdpURL || '#',
      catentryId: variantItem.uniqueID,
      upc: variantItem.partNumber || undefined,
      offerPrice: variantItem.offerPrice || undefined,
      isPolarized: variantItem.isPolarized || false,
      category: (variantItem.badgeBelowRight && variantItem.badgeBelowRight.label) || undefined,
      showIconCategory:
        (variantItem.badgeBelowRight && variantItem.badgeBelowRight.iconIdentifier) || undefined,
      saleBadge:
        variantItem.saleBadgeValue || variantItem.saleBadgeImage
          ? {
              badgeColor: variantItem.saleBadgeColor || undefined,
              badgeImage: variantItem.saleBadgeImage || undefined,
              badgeValue: variantItem.saleBadgeValue || undefined,
            }
          : undefined,
    }))
  }
  if (inputItem.saleBadgeValue || inputItem.saleBadgeImage) {
    let saleBadge = {}
    saleBadge.badgeColor = inputItem.saleBadgeColor || undefined
    saleBadge.badgeImage = inputItem.saleBadgeImage || undefined
    saleBadge.badgeValue = inputItem.saleBadgeValue || undefined
    outputItem.saleBadge = saleBadge
  }

  return outputItem
}

//used in getWishlistShareOptionsNormalized(). "position" is used to manually sort optionsNormalized
const shareOptionsMap = {
  whatsappShareURL: {
    label: 'ma_share_whatsapp',
    icon: 'common__icon--whatsapp',
    position: 0,
  },
  shareWishlistByEmailURI: {
    label: 'ma_share_email',
    icon: 'common__icon--mail',
    position: 1,
  },
  copyLinkShareURL: {
    label: 'ma_copy_link',
    icon: 'common__icon--link',
    position: 2,
  },
}

//function used to normalize the share options in wishlist
export const getWishlistShareOptionsNormalized = options => {
  let optionsNormalized = Array(Object.keys(shareOptionsMap).length)

  for (const option in options) {
    if (option in shareOptionsMap) {
      optionsNormalized[shareOptionsMap[option].position] = {
        label: shareOptionsMap[option].label,
        icon: shareOptionsMap[option].icon,
        url: options[option],
      }
    }
  }
  return optionsNormalized
}

// function used to normalize the single plp product in wishlist
export const getWishlistProductsNormalized = inputItem => {
  let outputItem = {}

  outputItem.mfpartnumber = inputItem.partNumber || undefined
  outputItem.colorsNumber = inputItem.colorsNumber
  outputItem.isStella = inputItem.isStella || false
  outputItem.img = inputItem.thumbSrc || undefined
  outputItem.isPolarized = inputItem.polarized || false
  outputItem.isEngravable = inputItem.isEngravable || false
  outputItem.isRoxable = inputItem.isRoxable
  outputItem.roxableLabel = inputItem.roxableLabel || undefined
  outputItem.userType = inputItem.userType || undefined
  outputItem.isOutOfStock = inputItem.isOutOfStock
  outputItem.isFindInStore = inputItem.isFindInStore || undefined
  outputItem.id = inputItem.catEntryId || undefined
  outputItem.customizableLabel = inputItem.customizableLabel || undefined
  outputItem.engravableLabel = inputItem.engravableLabel || undefined

  outputItem.listPrice =
    getPropertyByPath(inputItem.prices, priceNameConfig.ListPrice) || ''


  outputItem.offerPrice = getPropertyByPath(inputItem.prices, discountedPriceValue(inputItem.prices, priceNameConfig)) || ''

  outputItem.modelName = inputItem.modelName
  outputItem.isHideRemixLink = inputItem.isHideRemixLink || false
  outputItem.isCustomizable = inputItem.isCustomizable || false
  outputItem.isRemixed = inputItem.remixed || false
  outputItem.upc = inputItem.partNumber || undefined
  outputItem.imgHover = inputItem.imgHover || undefined
  outputItem.customizeUrl = inputItem.pdpCustomizeUrl || undefined
  outputItem.wishlistRelatedLinks = inputItem.wishlistRelatedLinks || {}
  outputItem.rx = inputItem.rx || false
  outputItem.rxPayload = JSON.parse(inputItem.rxPayload) || ''
  outputItem.clusterSize = inputItem.clusterSize || undefined
  outputItem.pdpURL = inputItem.pdpUrl || '#'
  outputItem.catentryId = inputItem.catEntryId || undefined
  outputItem.sequence = inputItem.sequence || undefined
  outputItem.brand = inputItem.brand || undefined
  outputItem.isEngravable = inputItem.isEngravable || false
  outputItem.isEmailMeWhenAvailable = inputItem.isEmailMeWhenAvailable || undefined
  outputItem.isExclusiveLogin = inputItem.isExclusiveLogin || undefined
  outputItem.isSale = inputItem.isSale || undefined
  let isLoyaltyActive = sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
  outputItem.isLoyaltyItem =
    isLoyaltyActive && inputItem.customeType && inputItem.customeType === 'Loyalty'

  let tealiumProduct = {
    Status: 'Available',
    Category: inputItem.brand.toUpperCase(),
    FrameType: '',
    LensType: inputItem.rx ? 'RX' : 'PLANO',
    Price: inputItem.listPrice || '',
    PriceFull: inputItem.listPrice || '',
    Units: 1,
    Sku: inputItem.modelName || '',
    ModelName: inputItem.fullModelName || '',
    FrameTechnology: inputItem.frameTechnology || '',
    LensTechnology: inputItem.LENS_TECHNOLOGY || '',
    Brand: inputItem.brand || '',
    Image: inputItem.thumbSrc || '',
    Url: inputItem.pdpUrl || '',
    uniqueid: inputItem.catEntryId,
    Conf_IsUpcSupported: '' + Number(inputItem.isCustomizable || inputItem.isEngravable),
    LensUPC: '',
    Engraving: '',
    Shape: '',
    ModelCode: '',
    Lens: '',
    Frame: '',
    Case: '',
    Size: '',
    LoyaltyExclusive: isLoyaltyActive ? (inputItem.isLoyaltyItem ? 'Y' : 'N') : '',
  }
  outputItem.tealiumProduct = tealiumProduct

  return outputItem
}

export const getFrameGeniusProductNormalized = product => {
  let normalizedProduct = {}
  normalizedProduct.brandName = product.brand
  normalizedProduct.brandlogoUrl = product.brandlogoUrl
  normalizedProduct.price = product.offerPrice || product.listPrice
  normalizedProduct.priceFull = product.offerPrice ? product.listPrice : ''
  normalizedProduct.productName = product.modelName
  normalizedProduct.frameColor = product.frameColor
  normalizedProduct.lensColor = product.lensColor
  normalizedProduct.rxAvailable = false // || product.isRoxable
  normalizedProduct.imageUrl = product.img
  normalizedProduct.url = window.location.origin + product.pdpURL
  normalizedProduct.inStock = !product.isOutOfStock || false
  normalizedProduct.upc = product.partNumber || undefined
  normalizedProduct.catentryId = product.catentryId || undefined
  return normalizedProduct
}

// function used to normalize the response on the list of plp
export const getPlpProductsNormalized = response => {
  // const { products, ...pagination } = response.data
  // const productsNormalized = products.map(product => getProductNormalized(product))
  // return {
  //   products: productsNormalized,
  //   ...pagination,
  // }
  if (response.data && response.data.plpView) {
    const { attributes, products, ...pagination } = response.data.plpView
    const attributesNormalized = attributes
    const productsNormalized = products.products.product.map(product =>
      getProductNormalized(product)
    )
    return {
      products: productsNormalized,
      attributes: attributesNormalized,
      ...pagination,
    }
  }
  return {}
}

export const getWishlistNormalized = response => {
  if (response.wlpView) {
    const { wishProducts, sortOptions, shareYourFavorites } = response.wlpView
    const sharedBy = response.sharedBy
    const productsNormalized = wishProducts
      ? wishProducts.map(product => getWishlistProductsNormalized(product))
      : undefined
    const shareOptionsListNormalized = getWishlistShareOptionsNormalized(shareYourFavorites)
    return {
      products: productsNormalized || [],
      sortOptionsList: sortOptions,
      shareOptionsList: shareOptionsListNormalized,
      shareInfo: { sharedBy: sharedBy },
    }
  }
}

const checkInnerList = (expectedFilterOptionsList, detail, isChanel) => {
  Object.keys(detail).forEach((currentDetail, index) => {
    const { entry, name, ...rest } = detail[currentDetail]
    const hasIcon =
      [
        'frontColorFacet',
        'eyeShape',
        'lensColorFacet',
        'frameShapeFacet',
        'dashCheckBoxes',
      ].includes(currentDetail) && !isChanel
    let listEntries = [],
      othersEntries = []
    // position taken from array of soredtOrderFilters. If not found the element is added at the end
    // if it's a dashbutton it goes on top
    let item = {
      label: name.toLowerCase(),
      groupName: currentDetail,
      position:
        sortedOrderFilters.indexOf(currentDetail) > -1
          ? sortedOrderFilters.indexOf(currentDetail)
          : // exceptions for dashbuttons to put them always on top of the filters
          currentDetail === 'dashCheckBoxes'
          ? -1
          : sortedOrderFilters.length + index,
    }
    if (entry) {
      entry.forEach(currentEntry => {
        //RX SGHDP-14983: hide if groupname == "prescription" and value != "TRUE"
        if (currentDetail != 'prescription' || currentEntry.value.includes('TRUE')) {
          listEntries.push({
            id: currentEntry.value,
            count: currentEntry.count,
            icon:
              hasIcon && currentEntry.cssClass
                ? currentEntry.cssClass.toLowerCase().replace(' ', '-')
                : undefined,
            iconSize: hasIcon ? 'small' : undefined,
            desc: currentEntry.description,
            facetIdentifier: currentEntry.facetIdentifier,
            label: currentEntry.label,
          })
        }
      })

      //https://luxotticaretail.atlassian.net/browse/SGHDP-23179:
      if (Object.keys(filterOptionsOrder).includes(item.groupName)) {
        listEntries = orderBy(listEntries, o =>
          filterOptionsOrder[item.groupName].indexOf(o.facetIdentifier)
        )
      }

      item.list = listEntries
    }
    // check safely that the rest property are not string (we consider only object)
    const others =
      'object' === typeof rest &&
      Object.keys(rest).length > 0 &&
      !Object.keys(rest).find(element => 'object' !== typeof rest[element])
    if (others) {
      checkInnerList(othersEntries, rest, isChanel)
      item.others = othersEntries
    }
    expectedFilterOptionsList.push(item)
  })
}

export const getFacetsNormalized = (input, isChanel) => {
  let normalizedFacets = {
    numResult: input.numResult,
  }
  let expectedSortOptionsList = []
  let expectedFilterOptionsList = []
  const sortOptions = input.sortOptions
  const productDetails = input.productDetails

  if (sortOptions && sortOptions.length > 0) {
    sortOptions.forEach(sortOption => {
      let selected = sortOption.selected
      expectedSortOptionsList.push({
        id: sortOption.entryValue,
        label: sortOption.label,
        selected,
      })
    })
  }

  if (
    productDetails.length > 0 &&
    !Object.keys(productDetails).find(element => 'object' !== typeof productDetails[element])
  ) {
    productDetails.forEach(detail => checkInnerList(expectedFilterOptionsList, detail, isChanel))
  }

  expectedFilterOptionsList.sort(function(a, b) {
    if (a.position > b.position) {
      return 1
    }
    if (a.position < b.position) {
      return -1
    }
    return 0
  })

  normalizedFacets = {
    ...normalizedFacets,
    sortOptionsList: expectedSortOptionsList,
    filterOptionsList: expectedFilterOptionsList,
  }
  const filteredHingeDistance = expectedFilterOptionsList.find(
    el => el.groupName === 'hingeDistance'
  )
  const hingeFacetDynamicId =
    filteredHingeDistance && filteredHingeDistance.list[0].id.split('%')[0]
  setSAHingeFilterId(hingeFacetDynamicId)
  return normalizedFacets
}

const getUrlParameter = (name, url) => {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]')
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)')
  var result = regex.exec(url) ? regex.exec(url)[1] : false
  return result === 'true' ? true : false
}

// function used to normalize the response on the sorting and filters options
export const getPlpFacetsNormalized = response => {
  const isChanel = getUrlParameter('isChanelCategory', response.config.url)
  if (response.data && response.data.facetView) {
    return getFacetsNormalized(response.data.facetView, isChanel)
  }
  return {}
}

export const getFindStoresListNormalized = response => {
  if (response.data) {
    const { locationDetails, emptyLocations } = response.data.result || response.data // TODO: remove result from response

    let storesNormalized = []

    if (!isEmpty(locationDetails)) {
      for (const store of locationDetails) {
        storesNormalized.push(getStoreNormalized(store))
      }
    }

    if (!isEmpty(emptyLocations)) {
      for (const store of emptyLocations) {
        storesNormalized.push(getStoreNormalized(store))
      }
    }

    storesNormalized.sort((a, b) => a.distanceMiles - b.distanceMiles)

    return { stores: storesNormalized }
  }
  return {}
}

// function used to normalize the store locator api response
export const getStoresListNormalized = response => {
  if (response.data) {
    const { locationDetails, latitude, longitude, radius } = response.data // TODO: remove result from response

    let storesNormalized = []
    let markersNormalized = []
    let filtersOptions = []
    const filters = []

    //build filters array: //todo will be passed by backend by market
    const marketFilters = [
      'storeType',
      'rxAvailable',
      'stellaAvailable',
      'storeBrands',
      'bookingAvailable',
    ]

    if (!isEmpty(locationDetails)) {
      //loop stores json to build stores, markers and filters arrays and location data to be saved in state
      for (const store of locationDetails) {
        storesNormalized.push(getStoreNormalized(store))
        if (getMarkerNormalized(store)) markersNormalized.push(getMarkerNormalized(store))

        //populate filters with options
        for (const filterName of marketFilters) {
          if (!filtersOptions[filterName])
            filterName == 'rxAvailable' ||
            filterName == 'stellaAvailable' ||
            filterName == 'bookingAvailable'
              ? (filtersOptions[filterName] = false)
              : (filtersOptions[filterName] = [])
          if (typeof store[filterName] == 'string') {
            //push single option without duplicates:
            if (filtersOptions[filterName].indexOf(store[filterName]) === -1)
              filtersOptions[filterName].push(store[filterName])
          } else if (Array.isArray(store[filterName])) {
            //merge new options array without duplicates:
            filtersOptions[filterName] = [
              ...new Set([...filtersOptions[filterName], ...store[filterName]]),
            ]
          } else if (
            typeof store[filterName] == 'boolean' &&
            !filtersOptions[filterName] &&
            store[filterName]
          ) {
            filtersOptions[filterName] = store[filterName]
          }
        }
      }

      for (const key of Object.keys(filtersOptions)) {
        let obj = {
          groupName: key,
          options: filtersOptions[key],
        }
        if (!isEmpty(obj.options) || obj.options) filters.push(obj) // eslint-disable-line
      }
      //sort filters brands
      if (find(filters, { groupName: 'storeBrands' })) {
        find(filters, { groupName: 'storeBrands' }).options.sort()
      }
      //sort filters storetype
      if (find(filters, { groupName: 'storeType' })) {
        let storeTypeFilterOptions = find(filters, { groupName: 'storeType' }).options
        storeTypeFilterOptions.sort()
        //move main stores to top
        storeTypeFilterOptions.find(
          (item, index) =>
            item == 'Sunglass Hut Outlet' &&
            storeTypeFilterOptions.unshift(storeTypeFilterOptions.splice(index, 1)[0])
        )
        storeTypeFilterOptions.find(
          (item, index) =>
            item == 'Sunglass Hut' &&
            storeTypeFilterOptions.unshift(storeTypeFilterOptions.splice(index, 1)[0])
        )
        find(filters, { groupName: 'storeType' }).options = storeTypeFilterOptions
      }
    }

    return {
      //on endpoint stores load we initialize stores, storesFiltered and storesVisible with the complete list (before filters are eventually applied)
      stores: storesNormalized, //complete list of stores retrieved from endpoint
      storesFiltered: storesNormalized, // list of stores filtered by currently selected filters (but not necessarily applied)
      storesVisible: storesNormalized, // list of stores visible after application of the selected filters
      storeMarkers: markersNormalized,
      latitude: parseFloat(latitude[0]),
      longitude: parseFloat(longitude[0]),
      radius: (radius && parseInt(radius[0])) || 0,
      filters: filters,
      //also reset filters applied and checked:
      filtersCheckedList: {
        storeType: [],
        storeBrands: [],
        bookingAvailable: [],
        rxAvailable: [],
        stellaAvailable: [],
      },
      filtersAppliedList: {
        storeType: [],
        storeBrands: [],
        bookingAvailable: [],
        rxAvailable: [],
        stellaAvailable: [],
      },
    }
  }
  return {}
}

// function used to normalize the single store
export const getStoreNormalized = inputItem => {
  const outputStore = {}

  outputStore.callReserve = inputItem.callReserve || ''
  outputStore.displayAddress = inputItem.displayAddress || ''
  outputStore.displayAddressLower = inputItem.displayAddress.toLowerCase() || ''
  outputStore.bookingAvailable = inputItem.bookingAvailable || ''
  outputStore.directionURL = inputItem.directionURL || ''
  outputStore.id = inputItem.id || ''
  outputStore.city = inputItem.city || ''
  outputStore.shippingDetails =
    mapValues(inputItem.shippingDetails, el => {
      return unescape(el)
    }) || {}
  outputStore.distanceMiles = inputItem.distanceMiles || ''
  outputStore.distanceKilometers = inputItem.distanceKilometers || ''
  outputStore.storeStatus = inputItem.storeStatus || ''
  // outputStore.name1 = inputItem.name1 || ''
  // outputStore.name2 = inputItem.name3 || ''
  outputStore.hours = inputItem.hours || []
  outputStore.storeBrands = inputItem.storeBrands || []
  outputStore.storeType = inputItem.storeType || ''
  outputStore.stellaAvailable = inputItem.stellaAvailable || ''
  outputStore.rxAvailable = inputItem.rxAvailable || ''

  //no active store at startup
  outputStore.active = false

  outputStore.stsAvailable = inputItem.stsAvailable || ''
  outputStore.cncAvailable = inputItem.cncAvailable || ''
  outputStore.nextWorkingDay = inputItem.nextWorkingDay || ''
  outputStore.storeLogo = inputItem.storeLogo || ''
  outputStore.hostName = inputItem.hostName || ''
  outputStore.phone = outputStore.shippingDetails.phone || inputItem.phone || ''
  outputStore.latitude = inputItem.latitude || ''
  outputStore.longitude = inputItem.longitude || ''
  outputStore.address = inputItem.address || ''
  outputStore.favourite = inputItem.favoriteStore
  const endpoints = {}
  endpoints.add = inputItem.favoriteStoreRelatedLinks?.addFavStoreURL || ''
  endpoints.remove = inputItem.favoriteStoreRelatedLinks?.removeFavStoreURL || ''
  endpoints.default = inputItem.favoriteStoreRelatedLinks?.defaultFavStoreURL || ''
  outputStore.endpoints = endpoints || {}
  outputStore.inventory = !isEmpty(inputItem.inventory)
    ? Number(inputItem.inventory[Object.keys(inputItem.inventory)[0]])
    : 0
  return outputStore
}

// function used to normalize the single store marker
export const getMarkerNormalized = inputItem => {
  const outputMarker = {}

  if (inputItem.latitude && inputItem.longitude) {
    outputMarker.position = {
      lat: parseFloat(inputItem.latitude),
      lng: parseFloat(inputItem.longitude),
    }
    outputMarker.id = inputItem.id || false
    outputMarker.icon = '/sghstatichtml/assets/images/icons/map-marker.svg'
    outputMarker.iconSelected = '/sghstatichtml/assets/images/icons/map-marker-selected.svg'
    outputMarker.title = inputItem.displayAddress || ''
    outputMarker.name = inputItem.displayAddress || ''

    return outputMarker
  } else {
    return false
  }
}

// function used to normalize the search suggestions
export const getSuggestionsNormalized = response => {
  let products = []
  let suggestedWords = []
  let spellcheck = []
  let collationQuery = ''
  let searchTermToReplace = ''
  let redirectUrl = ''

  if (response) {
    // console.log('protobuf response %o', response)

    // To read the protobuf response from the server we need
    // to decode the message using the PBmessage object
    // created by the .proto file
    const buf = protobuf.util.newBuffer(response.data)
    const decodedResponse = PBMessage.decode(buf)

    searchTermToReplace = decodedResponse.searchTerm
    products = decodedResponse.entry
    suggestedWords = decodedResponse.suggestedKeywords
      ? decodedResponse.suggestedKeywords.suggestKeyword
      : []
  }

  return {
    products: products,
    suggestedWords: suggestedWords,
    collationQuery: collationQuery,
    spellcheck: spellcheck,
    searchTermToReplace,
    redirectUrl: redirectUrl,
  }
}

// function used to normalize the order details api response
export const getOrderDetailsNormalized = (response, email) => {
  if (response.data) {
    let data = response.data
    let orderAddress = {}
    if (data.returnAddress !== undefined) {
      orderAddress = {
        id: Object.keys(data.returnAddress).toString(),
        value: Object.values(data.returnAddress).toString(),
      }
    }
    let addressListWithoutOrderAddress = data.returnAddressList.filter(function(address) {
      return Object.keys(address).toString() != orderAddress.id
    })
    let accountAddress = addressListWithoutOrderAddress.length
      ? addressListWithoutOrderAddress.map(address => {
          return {
            id: Object.keys(address).toString(),
            value: Object.values(address).toString(),
          }
        })
      : null
    let items = []
    let omniOrderType = data.omniOrderType
    let isReturnable = false
    let isShowNonReturnableMessage = false
    let isTracking = false
    data.orderItems.forEach(element => {
      let item = {}
      item.id = element.orderItemId
      item.rx = element.rx
      item.lensRx = element.lensRx
      item.lensBrand = element.lensBrand
      item.lensThicness = element.lensThicness
      item.lensTreatment = element.lensTreatment
      item.lensUse = element.lensUse
      item.upc = element.productId
      item.quantity = element.quantity
      item.estimatedShipping = element.estimatedDeliveryDate
      item.price = element.orderItemPrice
      item.refundTotal = element.refundTotal
      item.currency = element.currency
      item.link = element.pdpUrl
      item.img = element.thumbSrc
      item.brand = element.manufacturedName
      item.name = element.modelName
      item.size = element.size
      item.case = element.case
      item.caseImg = element.caseImg
      item.frameColor = element.FrameColor
      item.lensColor = element.LensColor
      item.lensTechnology = element.LensTechnology
      item.trackNumber = element.trackingNumber
      item.trackLink = element.trackingUrl
      item.carrier = element.carrier
      item.status = element.itemStatus
      item.remixed = element.remixed
      item.returnable = element.returnable
      item.showNonReturnableMessage = element.showNonReturnableMessage
      item.returnTill = element.returnTill
      item.stella = element.stella
      item.fulfillmentCenterId = element.fulfillmentCenterId
      item.serialNumber = element.serialNumber || null
      item.retailDeliveryFee = element.retailDeliveryFee
      item.protectionDetails = element.protectionDetails
      item.returnReason = Object.keys(element.returnReason).map(masterKey => ({
        optionGroup: masterKey,
        option: element.returnReason[masterKey].map(val => ({
          key: Object.keys(val)[0],
          name: Object.values(val)[0],
          type: masterKey,
        })),
      }))
      if (item.returnable === true) isReturnable = true
      if (item.showNonReturnableMessage === true) isShowNonReturnableMessage = true
      if (item.trackNumber) isTracking = true
      items.push(item)
    })

    items = getItemsWithEnhancedRxProducts(items)

    let country = null
    switch (data.storeId) {
      case '10152': {
        country = 'us'
        break
      }
      case '10154': {
        country = data.locale == 'en_CA' ? 'ca-en' : 'ca-fr'
        break
      }
      case '11351': {
        country = 'au'
        break
      }
      case '14351': {
        country = 'de'
        break
      }
      case '13251': {
        country = 'es'
        break
      }
      case '13801': {
        country = 'fr'
        break
      }
      case '11352': {
        country = 'uk'
        break
      }
      case '17001': {
        country = 'br'
        break
      }
      case '18001': {
        country = 'nz'
        break
      }
      case '19001': {
        country = 'nl'
        break
      }
      default: {
        country = 'us'
      }
    }

    return {
      number: data.orderId,
      isBoris: data.isBoris ? data.isBoris : false,
      originalOrderId: data.originalOrderId,
      originalUUID: data.originalUUID,
      rmaIds: data.rmaIds,
      rmaUUIDs: data.rmaUUIDs,
      userId: email,
      name: data.firstName,
      surname: data.lastName,
      date: data.orderDate,
      status: data.orderStatusLetter,
      boletinoURL: data.boletinoURL,
      statusExt: data.orderStatus,
      type: data.orderType,
      returnLabelUrl: data.returnLabelURL,
      returnLabel: null,
      shippingCost: data.totalShippingCharge,
      taxCost: data.totalSalesTax,
      retailDeliveryFee: data.retailDeliveryFee,
      subtotal: data.totalProductPrice,
      total: data.grandTotal,
      discount: data.discount,
      currency: data.currency,
      items: items,
      omniOrderType: omniOrderType,
      isReturnable: isReturnable,
      isShowNonReturnableMessage: isShowNonReturnableMessage,
      isTracking: isTracking,
      deliveryAddress: data.deliveryAddress,
      orderAddress: orderAddress,
      accountAddress: accountAddress,
      storeOrderId: data.storeId,
      localeOrder: data.locale,
      billingEmail: data.billingEmail,
      country: country,
      postCode: data.postCode ? data.postCode : '',
      protectionDetails: data.protectionDetails,
      totalInsurance: data.totalInsurance,
      uuid: data.uuid ? data.uuid : '',
    }
  }
  return {}
}

export const getOrderReturnHistoryNormalized = response => {
  let orderReturnHistoryList = []
  if (response.data) {
    let orderedList = orderBy(response.data, ['timePlaced'], ['desc'])
    Object.keys(orderedList).forEach(key => {
      orderReturnHistoryList.push(orderedList[key])
    })
    orderReturnHistoryList.forEach(order => {
      let items = getItemsWithEnhancedRxProducts(order.orderItems)
      order.returnDate = items.length ? getReturnDate(items[0].returnTill) : ''
      if (order.returnAddress)
        order.orderAddress = {
          id: Object.keys(order.returnAddress).toString(),
          value: Object.values(order.returnAddress).toString(),
        }
      switch (order.orderStatusLetter) {
        case 'P':
        case 'M':
        case 'N':
        case 'H':
          return (order.orderStatusNormalized = 'created')

        case 'X':
          return (order.orderStatusNormalized = 'cancelled')

        case 'Y':
          return (order.orderStatusNormalized = 'pendingBoleto')

        case '1':
          return (order.orderStatusNormalized = 'delivered')

        case 'O':
        case 'D':
          return (order.orderStatusNormalized = 'shipped')

        case 'U':
        case 'G':
        case 'K':
        case 'B':
        case 'R':
        case 'A':
          return (order.orderStatusNormalized = 'inProgress')

        case 'E':
          return (order.orderStatusNormalized = 'rejected')

        case 'V':
          return (order.orderStatusNormalized = 'pendingPrescription')

        case 'W':
          return (order.orderStatusNormalized = 'prescriptionToBeValidated')

        case 'F':
          return (order.orderStatusNormalized = 'completed')

        case 'T':
        case 'Q':
          return (order.orderStatusNormalized = 'returned')
        case 'C':
          return (order.orderStatusNormalized = 'collected')

        default:
          return (order.orderStatusNormalized = '')
      }
    })
  }
  return orderReturnHistoryList
}

const getReturnDate = returnTill => {
  if (returnTill) {
    let date = new Date(returnTill).toString().split(' ')
    return `${date[0]} ${date[1]} ${date[2]}`
  }
  return ''
}

// function used to normalize the minicart json response
export const getMinicartNormalized = (response, productId) => {
  if (response) {
    let data = response
    let item = []
    let itemPrice = ''
    let itemsInCart = 0

    //this action takes for include right gift card quantity
    //because on PDP user can add card quantity as select option
    data.orderItems.forEach((currentItem) => {
      itemsInCart += currentItem.quantity
    })

    item = data.orderItems
    //get last orderItemId
    if (item.length > 1) {
      item = item.filter(item => item.productId.indexOf(productId) !== -1)
      item = item.sort((a, b) => a.orderItemId.localeCompare(b.orderItemId))
    }
    item = item.pop()

    itemPrice = item.PriceWithEngraving ? item.PriceWithEngraving : item.orderItemPrice
    return {
      id: item.orderItemId || '',
      modelName: item.modelName || '',
      quantity: item.quantity || '',
      price: itemPrice || '',
      listPrice: item.listPrice || '',
      currency: item.currency || '',
      img: item.fullImgSrc || '',
      brand: item.manufacturedName || '',
      frameColor: item.FrameColor || '',
      lensColor: item.LensColor || '',
      lensTechnology: item.LensTechnology || '',
      remixed: item.remixed || '',
      number: data.orderId || '',
      billingEmail: data.billingEmail || '',
      shippingCost: data.totalShippingCharge || '',
      taxCost: data.totalSalesTax || '',
      subtotal: data.totalProductPrice || '',
      total: data.grandTotal || '',
      records: itemsInCart || '',
      giftCardRemovedFlag: data.itemsWereRemoved || false,
      stellaItem: item.stellaItem || false,
      isSale: item.isSale === 'TRUE' || false,
      objectID: item.partNumber || '',
      insurance: item.insurance || '',
    }
  }
  return {}
}


// function used to normalize the minicart inventoryavailability api response
export const getRecommendationEspotProductNormalized = response => {
  if (response.data) {
    let data = response.data.InventoryAvailability
    let products = []
    let items = []
    let countProducts = 0

    for (let product of suggestionProducts) {
      for (let productStatus of data) {
        if (
          productStatus.productId === product.productId &&
          productStatus.inventoryStatus === 'Available'
        ) {
          products.push(product)
        }
      }
    }
    if (products) {
      countProducts = products.length
      countProducts > 3 ? (countProducts = 3) : countProducts
      const productsToShow = products.slice(0, countProducts)
      productsToShow.forEach(element => {
        let item = {}
        item.productName = element.description[0].productName
        item.standardPrice = Boolean(window.algoliaConfig?.isEarlyAccessParam)
          ? element.contractPrice[0].contractPrice
          : element.standardPrice
          ? element.standardPrice
          : element.listPrice
        item.fullPrice = element.listPrice || ''
        item.productId = element.productId
        item.img = element.description[0].fullImage
        item.brandName = brandName || ''
        item.description = element.description[0].longDescription
        item.productPartNumber = element.productPartNumber
        let isLoyaltyActive = sessionStorage.getItem('isLoyaltyProgramEnabled') === 'true'
        item.isLoyaltyItem =
          isLoyaltyActive && element.customeType && element.customeType === 'Loyalty'

        let tealiumProduct = {
          Sku: item.productName,
          Category: item.brandName.toUpperCase(),
          Price: item.standardPrice,
          PriceFull: item.standardPrice,
          ModelName: item.productName,
          UPC: item.productPartNumber,
          uniqueid: item.productId,
          Engraving: 'N',
          Status: 'Available',
          Type: 'STD',
          Units: 1,
          ModelCode: '',
          Lens: '',
          Frame: '',
          Case: '',
          LoyaltyExclusive: isLoyaltyActive ? (item.isLoyaltyItem ? 'Y' : 'N') : '',
        }
        item.tealiumProducts = tealiumProduct
        items.push(item)
      })
    }
    return {
      items: items || '',
      count: countProducts || '',
    }
  }
  return {}
}

export const getMinicartPromotionNormalized = response => {
  if (response.data) {
    let data = response.data
    let content = ''

    const [arrayContent] = data.MarketingSpotData.map(el => {
      if (el.baseMarketingSpotActivityData) {
        return el.baseMarketingSpotActivityData
      } else {
        return false
      }
    })

    if (arrayContent) {
      for (const el of arrayContent) {
        if (el.baseMarketingSpotDataType === 'MarketingContent') {
          content = el.marketingContentDescription[0].marketingText
          break
        }
      }
    }

    return {
      content: content || '',
    }
  }
  return {}
}

const getItemsWithEnhancedRxProducts = items => {
  items.forEach(item => {
    if (!item.lensRx) return

    let rxFrameItem = items.find(matchingItem => matchingItem.rx)
    if (!rxFrameItem) return

    const lensInfoData = [
      item.lensBrand,
      item.lensThicness,
      item.lensTreatment,
      item.lensUse,
    ].filter(info => info)
    rxFrameItem.price
      ? (rxFrameItem.price = Number(rxFrameItem.price) + Number(item.price))
      : (rxFrameItem.listPrice = Number(rxFrameItem.listPrice) + Number(item.listPrice)),
      (rxFrameItem.orderItemPrice =
        Number(rxFrameItem.orderItemPrice) + Number(item.orderItemPrice))
    rxFrameItem.prescriptionInfo = lensInfoData.join(' / ')
  })

  return items
}

// function used to normalize the early access subscribe errors api response
export const getEarlyAccessSubscribeError = response => {
  let errors
  let obejectError = {}
  if (response.data) {
    let data = response.data
    errors = data.messages
    Object.entries(errors).forEach(([key, value]) => {
      obejectError[key] = value.error
    })
    return obejectError
  }

  return obejectError
}

export const getProfileDetailsNormalized = response => {
  let objectPerson = {}
  if (response.data) {
    const {
      loyaltySubscription,
      gender,
      formattedBirthday,
      userId,
      attributes,
      logonEmail,
      userField1,
      optInInfo,
      contact,
      dateOfBirth,
      loyaltyCard,
      registrationApprovalStatus,
    } = response.data
    //Personal information
    objectPerson.registeredEmail = logonEmail
    objectPerson.userId = userId
    objectPerson.loyaltySubscription = loyaltySubscription
    objectPerson.personalInfo = {}
    attributes &&
      attributes.map(attribute => {
        if (attribute.pProfileAttrKey === 'demographicField5') {
          objectPerson.personalInfo.lastName = attribute.pProfileAttrValue
        }
        if (attribute.pProfileAttrKey === 'demographicField7') {
          objectPerson.personalInfo.firstName = attribute.pProfileAttrValue
        }
      })
    objectPerson.personalInfo.gender = gender
    objectPerson.personalInfo.registrationApprovalStatus = registrationApprovalStatus
    objectPerson.personalInfo.formattedBirthday = formattedBirthday ? formattedBirthday : ''
    objectPerson.personalInfo.phoneNumber = userField1
    optInInfo && optInInfo[0].status
      ? (objectPerson.marketing = true)
      : (objectPerson.marketing = false)
    objectPerson.attributes = []
    let arrKey = [
      { label: 'First name', name: 'firstName' },
      { label: 'Last name', name: 'lastName' },
      { label: 'Gender', name: 'gender' },
      { label: 'Birth date', name: 'formattedBirthday' },
      { label: 'Phone number', name: 'phoneNumber' },
    ]
    arrKey.map(ele => {
      if (
        typeof objectPerson.personalInfo[ele.name] !== 'undefined' &&
        objectPerson.personalInfo.hasOwnProperty(ele.name)
      ) {
        objectPerson.attributes.push({ key: ele.label, value: objectPerson.personalInfo[ele.name] })
      } else {
        objectPerson.attributes.push({ key: ele.label, value: '' })
        objectPerson.incomplete = true
      }
      return objectPerson
    })

    const birth = new Date(dateOfBirth)
    objectPerson.personalInfo.birthdate = formattedBirthday ? birth.toLocaleDateString('fr-CA') : ''
    objectPerson.personalInfo.day = birth.getDate().toString()
    objectPerson.personalInfo.month = (birth.getMonth() + 1).toString()
    if (birth.getFullYear() === 1200) {
      objectPerson.personalInfo.year = ''
    } else {
      objectPerson.personalInfo.year = birth.getFullYear().toString()
    }
    //Login information
    let password = String.fromCharCode(9679)
    password = password.repeat(7)
    objectPerson.loginInfo = [logonEmail, password]
    //Address book
    objectPerson.address = []
    contact
      ? contact.forEach(element => {
          let singleAddress = {}
          singleAddress.email = element.email1 || ''
          singleAddress.firstName = element.firstName || ''
          singleAddress.lastName = element.lastName || ''
          singleAddress.streetAddress = element.addressLine ? element.addressLine[0] : ''
          element.addressLine && element.addressLine[1] !== ''
            ? (singleAddress.municipio = element.addressLine[1])
            : ''
          element.addressLine && element.addressLine[2] !== ''
            ? (singleAddress.colonia = element.addressLine[2])
            : ''
          singleAddress.phoneNumber = element.phone1 || ''
          singleAddress.addressApartment = element.internalOfficeAddress || ''
          singleAddress.zipCode = element.zipCode || ''
          singleAddress.state = element.state || ''
          singleAddress.city = element.city || ''
          singleAddress.country = element.country || ''
          singleAddress.addressId = element.addressId || ''
          singleAddress.addressType = element.addressType || ''
          singleAddress.primary = element.primary || ''
          singleAddress.nickName = element.nickName || ''
          singleAddress.fax2 = element.fax2 || ''
          element.attributes && element.attributes[0] && element.attributes[0].key === 'billingCode'
            ? (singleAddress.billingCode = element.attributes[0].value)
            : ''
          element.attributes &&
          element.attributes[1] &&
          element.attributes[1].key === 'billingCodeType'
            ? (singleAddress.billingCodeType = element.attributes[1].value)
            : ''
          singleAddress.mobilePhone1Country = element.mobilePhone1Country || ''
          singleAddress.phone2Type = element.phone2Type || ''
          objectPerson.address.push(singleAddress)
        })
      : ''

    let optInInfoForEmail = optInInfo && optInInfo.find(item => item.eMail === logonEmail)
    optInInfoForEmail &&
    optInInfoForEmail.attributes &&
    optInInfoForEmail.attributes.receiveSms === 'true'
      ? (objectPerson.personalInfo.receiveSMS = true)
      : (objectPerson.personalInfo.receiveSMS = false)
    //Preferred stores
    objectPerson.store = []
    objectPerson.isLoyaltyActive =
      loyaltyCard?.status && loyaltyCard?.status === 'ACTIVE' ? true : false
    objectPerson.loyaltyStatus = loyaltyCard?.status ? loyaltyCard.status : ''
    objectPerson.loyaltyCardNumber = loyaltyCard?.cardNumber ? loyaltyCard.cardNumber : ''
    objectPerson.loyaltyCommunication = loyaltyCard?.loyaltyCommunication
      ? loyaltyCard.loyaltyCommunication
      : ''
    objectPerson.loyaltyJwtToken = loyaltyCard?.jwtToken ? loyaltyCard.jwtToken : ''
    objectPerson.loyaltyNotMemberJwtToken = loyaltyCard?.notMemberJwtToken
      ? loyaltyCard.notMemberJwtToken
      : ''
    objectPerson.loyaltyTier = loyaltyCard?.tier ? loyaltyCard.tier : ''
  }
  return objectPerson
}
export const getAddressNormalized = response => {
  let address = []
  if (response.data) {
    const { contact } = response.data
    contact
      ? contact.forEach(element => {
          let singleAddress = {}
          singleAddress.email = element.email1 || ''
          singleAddress.firstName = element.firstName || ''
          singleAddress.lastName = element.lastName || ''
          singleAddress.streetAddress = element.addressLine ? element.addressLine[0] : ''
          element.addressLine && element.addressLine[1] !== ''
            ? (singleAddress.municipio = element.addressLine[1])
            : ''
          element.addressLine && element.addressLine[2] !== ''
            ? (singleAddress.colonia = element.addressLine[2])
            : ''
          singleAddress.phoneNumber = element.phone1 || ''
          singleAddress.addressApartment = element.internalOfficeAddress || ''
          singleAddress.zipCode = element.zipCode || ''
          singleAddress.state = element.state || ''
          singleAddress.city = element.city || ''
          singleAddress.country = element.country || ''
          singleAddress.addressId = element.addressId || ''
          singleAddress.addressType = element.addressType || ''
          singleAddress.primary = element.primary || ''
          singleAddress.nickName = element.nickName || ''
          singleAddress.fax2 = element.fax2 || ''
          element.attributes && element.attributes[0] && element.attributes[0].key === 'billingCode'
            ? (singleAddress.billingCode = element.attributes[0].value)
            : ''
          element.attributes &&
          element.attributes[1] &&
          element.attributes[1].key === 'billingCodeType'
            ? (singleAddress.billingCodeType = element.attributes[1].value)
            : ''
          singleAddress.mobilePhone1Country = element.mobilePhone1Country || ''
          singleAddress.phone2Type = element.phone2Type || ''
          address.push(singleAddress)
        })
      : ''
  }
  return address
}

export const getStateAndZipNormalized = response => {
  const { globalCountry } = wcs.getStoreConfig()
  const stateByCountry = response.countryList.find(obj => obj.id === globalCountry)

  return {
    states: stateByCountry.states ? stateByCountry.states : null,
    zipRegex: stateByCountry.validationRegex ? stateByCountry.validationRegex : null,
    countryName: stateByCountry.countryName ? stateByCountry.countryName : null,
  }
}

export const getShortcodeNormalized = response => {
  let shortcode = {}
  const productType = ['STANDARD', 'STELLA', 'REMIX', 'RX']
  const shippingMethod = [
    'STANDARD',
    'SAMENEXT',
    'EXPRESS',
    'USPS',
    'GREEN',
    'MILITARY',
    'PICKUPSTORE',
    'BOSS',
    'BOPS',
  ]

  if (response.data) {
    for (let [key, value] of Object.entries(response.data)) {
      if (key === 'shipping') {
        for (let [key, value] of Object.entries(value)) {
          if (key === 'COLLECT_IN_STORE') key = 'PICKUPSTORE'
          else if (key === 'EXPEDITE_SHIPPING') key = 'EXPRESS'
          else if (key === 'SAME_BUSINESS_DAY') key = 'SAMENEXT'
          else if (key === 'USPS_ONLY') key = 'USPS'
          if (value.price) {
            shortcode[`SHIPPING_COST_${key}`] = value.price
            productType.forEach(type => (shortcode[`SHIPPING_COST_${key}_${type}`] = value.price))
          }
          if (value.cutoff) {
            shortcode[`CUTOFF_${key}`] = value.cutoff
            productType.forEach(type => (shortcode[`CUTOFF_${key}_${type}`] = value.cutoff))
          }
          if (value.edd) shortcode[`LEAD_TIME_${key}`] = value.edd
        }
      } else if (key === 'productType') {
        for (let [key, value] of Object.entries(value)) {
          if (value.edd)
            shippingMethod.forEach(
              shipping =>
                (shortcode[`LEAD_TIME_${shipping}_${key}`] = shortcode[`LEAD_TIME_${shipping}`]
                  ? (parseInt(value.edd) + parseInt(shortcode[`LEAD_TIME_${shipping}`])).toString()
                  : value.edd)
            )
        }
      } else if (key === 'RETURN_DAYS_TYPE') shortcode['RETURN_DAYS'] = value
      else shortcode[key] = value
    }
  }

  return shortcode
}

export const getFavouriteStoresNormalized = ({ favoriteStoreView }) => {
  /*"rticAvailable": true,
  "sfsAvailable": true,
  "stsAvailable": true,
  "fasAvailable": true,
  "bookingAvailable": true,
  "stellaAvailable": true,*/

  const listStore = []
  favoriteStoreView.favStoreLocation.map(store => {
    const objStore = {}
    objStore.id = store.id
    objStore.address = store.address
    objStore.state = store.state
    objStore.city = store.city
    objStore.zip = store.zip
    objStore.name = store.displayAddress
    objStore.logo = store.logo
    objStore.direction = store.directionURL
    objStore.phoneNumber = store.phone
    objStore.default = store.defaultFavoriteStore
    objStore.booking = store.bookingAvailable
    objStore.hours = store.hours

    const service = []
    store.cncAvailable ? service.push({ key: 'ma_pick_up_in_store' }) : null
    store.callReserve ? service.push({ key: 'ma_call_and_reserve' }) : null
    store.bookingAvailable ? service.push({ key: 'ma_book_an_appointment' }) : null
    objStore.services = service

    const endpoints = {}
    endpoints.add = store.favoriteStoreRelatedLinks.addFavStoreURL
    endpoints.remove = store.favoriteStoreRelatedLinks.removeFavStoreURL
    endpoints.default = store.favoriteStoreRelatedLinks.defaultFavStoreURL
    objStore.endpoints = endpoints

    listStore.push(objStore)
  })
  return listStore
}

export const getAppointmentsBookingNormalized = appointments => {
  let objAppointments = []
  let upcomings = []
  let pastAppointments = []
  const { upcoming, past } = appointments
  upcoming.map(appointment => {
    const {
      directionURL,
      displayAddress,
      address,
      phone,
      city,
      countryCode,
      zip,
    } = appointment.location

    let objUpcoming = {}
    objUpcoming.id = appointment.visit_id
    objUpcoming.reschedule = appointment.reschedule_url
    objUpcoming.cancel = appointment.cancel_url
    objUpcoming.addCalendar = 'addcalendar'
    objUpcoming.date = appointment.date
    objUpcoming.appointmentDate = appointment.appointment_date
    objUpcoming.appointmentTime = appointment.appointment_time
    objUpcoming.timeZone = appointment.location.timeZone
    objUpcoming.purpose = appointment.purpose
    objUpcoming.host = displayAddress
    objUpcoming.address = address
    objUpcoming.city = city
    objUpcoming.country = countryCode
    objUpcoming.zipCode = zip
    objUpcoming.direction = directionURL
    objUpcoming.phone = phone
    objUpcoming.contact = appointment.contact
    upcomings.push(objUpcoming)
  })
  past.map(appointment => {
    const {
      directionURL,
      displayAddress,
      address,
      phone,
      city,
      countryCode,
      zip,
    } = appointment.location
    let objPastAppointment = {}
    objPastAppointment.id = appointment.visit_id
    objPastAppointment.date = appointment.date
    objPastAppointment.appointmentDate = appointment.appointment_date
    objPastAppointment.appointmentTime = appointment.appointment_time
    objPastAppointment.purpose = appointment.purpose
    objPastAppointment.host = displayAddress
    objPastAppointment.address = address
    objPastAppointment.city = city
    objPastAppointment.country = countryCode
    objPastAppointment.zipCode = zip
    objPastAppointment.direction = directionURL
    objPastAppointment.phone = phone
    pastAppointments.push(objPastAppointment)
  })
  objAppointments.upcoming = upcomings
  objAppointments.past = pastAppointments

  return objAppointments
}

export const getErrorFromGiftCardBalance = errorResponse => {
  let jsonString = errorResponse.match(/\{(.|\n)*\}/gm)[0]
  let obj = JSON.parse(jsonString)
  const errorMap = {
    _ERR_GC_GENERIC_ERROR: 'number',
    _ERR_GC_INVALID_PIN: 'pin',
    _ERR_RECAPTCHA_VERIFICATION_ERROR: 'recaptcha',
    default: 'error',
  }
  let errorField = errorMap[obj.errorMessageKey] || errorMap['default']

  return { error: errorField }
}

export const getLoyaltyProductsNormalized = data => {
  const currentDate = new Date()

  let listBenefits = []
  if (data.benefits) {
    listBenefits = data.benefits
      .filter(
        ({
          status,
          startDate,
          endDate,
          redemptionRule,
          promocodeEcommerce,
          benefitProducts,
          category,
        }) =>
          status === 'AVAILABLE' &&
          currentDate >= new Date(startDate) &&
          currentDate <= new Date(endDate) &&
          (redemptionRule === 'PROMO_CODE' || redemptionRule === 'PRODUCT_CODE') &&
          !isEmpty(promocodeEcommerce) &&
          (isEmpty(benefitProducts) ||
            (category === 'GIFT' && benefitProducts.some(product => product.inventoryQuantity > 0)))
      )
      .map(
        ({
          image,
          benefitCartName,
          benefitCartDescription,
          startDate,
          endDate,
          ctaCart,
          category,
          isPromoApplied,
          promocodeEcommerce,
          benefitProducts,
          redemptionRule,
        }) => ({
          image,
          benefitCartName,
          benefitCartDescription,
          startDate: new Date(startDate).toLocaleDateString(getCurrentLocale(), { timeZone: 'UTC' }),
          endDate: new Date(endDate).toLocaleDateString(getCurrentLocale(), { timeZone: 'UTC' }),
          ctaCart,
          category,
          promocodeEcommerce,
          benefitProducts,
          alreadyInCart: isEmpty(benefitProducts) ? isPromoApplied : benefitProducts[0].inCart,
          catentryId: isEmpty(benefitProducts) ? '' : benefitProducts[0].catEntryId,
          redemptionRule,
        })
      )
  }
  return listBenefits
}
