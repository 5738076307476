import { parseValue } from '../../libs/utils/string'

export function parseCMSPlacementData(data) {
  const container = document.createElement('div')
  container.innerHTML = data.content

  const children = container.querySelectorAll('div[placement-id]')
  let placements = []

  children.forEach(child => {
    const placementChild = child.children[0]
    if (placementChild) {
      const name = placementChild.localName
      let templates = {}
      let props = {}

      Array.from(placementChild.children).forEach(template => {
        if (template.attributes[0]) {
          templates[template.attributes[0].localName.replace('#', '')] =
            template.innerHTML || template.content?.textContent || ''
        }
      })

      Array.from(placementChild.attributes).forEach(attribute => {
        props[attribute.localName.replace(':', '')] = parseValue(attribute.value)
      })

      placements.push({ name, templates, props })
    }
  })

  return placements
}
