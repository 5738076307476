interface AddToBagButtonProps {
  isLoyaltyOnly: boolean;
  isUserLoggedIn: boolean;
  isUserLoyalty: boolean;
  isAvailableQuantityMoreThanZero: boolean;
  isProductActive: boolean;
  isOnlineOnly: boolean;
  isShippingFromStoreAvailable: boolean;
  mainProduct: {
    availableForCNC: boolean;
    availableForRITC: boolean;
  };
}

type ButtonAction = 'loyaltyExclusive' | 'addToBag' | 'emailMe' | 'pickup' | '';

export const addToBagButtonAction = ({
  isLoyaltyOnly,
  isUserLoggedIn,
  isUserLoyalty,
  isAvailableQuantityMoreThanZero,
  isProductActive,
  isOnlineOnly,
  mainProduct
}: AddToBagButtonProps): ButtonAction => {
  if (isLoyaltyOnly && (!isUserLoggedIn || !isUserLoyalty)) {
    return 'loyaltyExclusive'
  } else {
    if (isAvailableQuantityMoreThanZero) {
      return 'addToBag'
    } else {
      if (!isProductActive) {
        return ''
      } else {
        if (isOnlineOnly) {
          return 'emailMe'
        } else {
          if (mainProduct.availableForCNC && mainProduct.availableForRITC) {
            return 'pickup'
          } else return 'emailMe'
        }
      }
    }
  }
}