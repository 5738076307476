import wcs from './index'
import axios from 'axios'
import {
  getProfileDetailsNormalized,
  getAddressNormalized,
  getOrderReturnHistoryNormalized,
  getStateAndZipNormalized,
  getFavouriteStoresNormalized,
  getAppointmentsBookingNormalized,
  getLoyaltyProductsNormalized,
} from '../normalizer'

export const userEndpoint = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const path = parameters.isJoin ? 'person' : 'loginidentity'
  const url = `${domain}/wcs/resources/store/${storeId}/${path}?updateCookies=true&langId=${langId}`
  const params = parameters.isJoin
    ? {
        logonId: parameters.email,
        logonIdVerify: parameters.email,
        logonPassword: parameters.password,
        logonPasswordVerify: parameters.password,
        demographicField7: parameters.firstName,
        demographicField5: parameters.lastName,
        gender: parameters.gender,
        dateOfBirth: parameters.birthdate,
        userField1: parameters.phoneNumber,
        optinStatus: parameters.marketing_subscribe_message ? '1' : '0',
        receiveSMS: parameters.marketing_subscribe_sms ? 'true' : 'false',
        sendMeEmail: parameters.marketing_subscribe_message ? 'true' : 'false',
        loyaltySubscription: parameters.loyalty_subscribe_message ? 'true' : 'false',
        account: parameters.email,
        accountType: '1',
        loyaltyCommunication:parameters.loyalty_communication ? 'true' : 'false',
      }
    : {
        logonId: `${storeId}|${parameters.email.toLowerCase()}`,
        logonPassword: parameters.password,
      }
  const paramsWithoutEmptyValue = Object.fromEntries(
    Object.entries(params).filter(value => value[1])
  )
  let data = {}

  try {
    data = await axios.post(url, paramsWithoutEmptyValue)
  } catch (error) {
    data.error =
      error?.response &&
      error?.response?.data?.errors?.length &&
      error?.response?.data?.errors[0]?.errorMessage
        ? {
            errorKey: error.response.data.errors[0].errorKey.toLowerCase(),
            errorMessage: error.response.data.errors[0].errorMessage,
          }
        : 'Error'
  }
  return data
}
export const userUpdateEndpoint = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/person/@self?updateCookies=true&responseFormat=json&action=updateUserRegistration&langId=${langId}`
  let params = {}
  if (parameters.marketingPath) {
    params = {
      logonId: parameters.registeredEmail,
      receiveEmail: parameters.marketing_subscribeNL_message ? 'true' : 'false',
      receiveSMS: parameters.marketing_subscribe_sms ? 'true' : 'false',
    }
  } else if (parameters.emailPath) {
    params = {
      logonId: parameters.newEmail,
    }
  } else if (parameters.passwordPath) {
    params = {
      logonId: parameters.registeredEmail,
      logonPassword: parameters.newPassword,
      logonPasswordVerify: parameters.confirmPassword,
      logonPasswordOld: parameters.currentPassword,
      'g-recaptcha-response': parameters.recaptcha,
    }
  } else if (parameters.loyalty_communications) {
    params = {
      logonId: parameters.registeredEmail,
      logonPassword: parameters.newPassword,
      loyaltySubscription: parameters.loyalty_communications,
      receiveEmail: parameters.marketing_subscribeNL_message ? 'true' : 'false',
      receiveSMS: parameters.marketing_subscribe_sms ? 'true' : 'false',
    }
  } else {
    params = {
      logonId: parameters.registeredEmail,
      demographicField7: parameters.firstName,
      demographicField5: parameters.lastName,
      gender: parameters.gender,
      dateOfBirth: parameters.birthdate,
      userField1: parameters.phoneNumber,
      receiveSMS: parameters.marketing_subscribe_sms ? 'true' : 'false',
    }
  }

  let data = {}

  try {
    data = await axios.put(url, params)
  } catch (error) {
    data.error =
      error.response &&
      error.response.data.errors.length &&
      error.response.data.errors[0].errorMessage
        ? error.response.data.errors[0].errorMessage
        : 'Error'
  }
  return data
}

export const addUserAddress = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/person/@self/contact?langId=${langId}`
  const today = new Date()
  const dd = String(today.getDate()).padStart(2, '0')
  const mm = String(today.getMonth() + 1).padStart(2, '0')
  const nickname =
    today.getFullYear() +
    mm +
    dd +
    today.getHours() +
    today.getMinutes() +
    today.getSeconds() +
    today.getMilliseconds()
  const defaultDelivery = parameters.default_delivery_address ? 'S' : ''
  const defaultBilling = parameters.default_billing_address ? 'B' : ''
  const userDefaultAddress = defaultDelivery + defaultBilling
  const defaultAddress = 'SB'
  const params = {
    nickName: nickname,
    email1: parameters.email,
    firstName: parameters.firstName,
    lastName: parameters.lastName,
    addressLine: [
      parameters.streetAddress,
      setAddress2DBField(parameters) || '',
      parameters.colonia ? parameters.colonia : '',
    ],
    addressType: userDefaultAddress === '' ? defaultAddress : userDefaultAddress,
    primary: userDefaultAddress != '' ? 'true' : 'false',
    phone1: parameters.phoneNumber,
    zipCode: parameters.zipCode,
    state: parameters.state.stateId ? parameters.state.stateId : '-',
    city: parameters.city,
    country: parameters.countryId,
    fax2: parameters.fax2 ? parameters.fax2 : '',
    billingCode: parameters.billingCode ? parameters.billingCode : '',
    billingCodeType: parameters.billingCodeType ? parameters.billingCodeType : '',
    mobilePhone1Country: parameters.mobilePhone1Country ? parameters.mobilePhone1Country : '',
    phone2Type:
      parameters.phone2Type === undefined || parameters.phone2Type === ''
        ? ''
        : parameters.phone2Type.toString(),
  }
  let data = {}

  try {
    data = await axios.post(url, params)
  } catch (error) {
    data.error =
      error.response &&
      error.response.data.errors.length &&
      error.response.data.errors[0].errorMessage
        ? error.response.data.errors[0].errorMessage
        : 'Error'
  }
  return data
}

export const editUserAddress = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/person/@self/contact/${parameters.nickName}?langId=${langId}`
  const defaultDelivery = parameters.default_delivery_address ? 'S' : ''
  const defaultBilling = parameters.default_billing_address ? 'B' : ''
  const userDefaultAddress = defaultDelivery + defaultBilling
  const defaultAddress = 'SB'
  const params = {
    email1: parameters.email,
    firstName: parameters.firstName,
    lastName: parameters.lastName,
    addressLine: [
      parameters.streetAddress,
      setAddress2DBField(parameters) || '',
      parameters.colonia ? parameters.colonia : '',
    ],
    addressType: userDefaultAddress === '' ? defaultAddress : userDefaultAddress,
    primary: userDefaultAddress != '' ? 'true' : 'false',
    phone1: parameters.phoneNumber,
    zipCode: parameters.zipCode,
    state: parameters.state.stateId ? parameters.state.stateId : '-',
    city: parameters.city,
    country: parameters.countryId,
    fax2: parameters.fax2 ? parameters.fax2 : '',
    billingCode: parameters.billingCode ? parameters.billingCode : '',
    billingCodeType: parameters.billingCodeType ? parameters.billingCodeType : '',
    mobilePhone1Country: parameters.mobilePhone1Country ? parameters.mobilePhone1Country : '',
    phone2Type:
      parameters.phone2Type === undefined || parameters.phone2Type === ''
        ? ''
        : parameters.phone2Type.toString(),
  }
  let data = {}

  try {
    data = await axios.put(url, params)
  } catch (error) {
    data.error = error.response && error.response.status && error.response.statusText
  }
  return data
}
export const updateShippingAndBilling = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/person/@self/contact/${parameters.nickName}?langId=${langId}`
  const params = {
    email1: parameters.email1,
    firstName: parameters.firstName,
    lastName: parameters.lastName,
    addressLine: [
      parameters.streetAddress,
      setAddress2DBField(parameters) || '',
      parameters.colonia ? parameters.colonia : '',
    ],
    addressType: parameters.addressType,
    phone1: parameters.phone1,
    zipCode: parameters.zipCode,
    state: parameters.state,
    city: parameters.city,
    country: parameters.country,
    primary: parameters.primary,
    fax2: parameters.fax2 ? parameters.fax2 : '',
    billingCode: parameters.billingCode ? parameters.billingCode : '',
    billingCodeType: parameters.billingCodeType ? parameters.billingCodeType : '',
    mobilePhone1Country: parameters.mobilePhone1Country ? parameters.mobilePhone1Country : '',
    phone2Type:
      parameters.phone2Type === undefined || parameters.phone2Type === ''
        ? ''
        : parameters.phone2Type.toString(),
  }
  let data = {}

  try {
    data = await axios.put(url, params)
  } catch (error) {
    data.error = error.response && error.response.status && error.response.statusText
  }
  return data
}
export const deleteUserAddress = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/person/@self/contact/${parameters}?langId=${langId}`
  let data = {}

  try {
    data = await axios.delete(url)
  } catch (error) {
    data.error = error.response && error.response.status && error.response.statusText
  }
  return data
}

export const profileDetailsEndpoint = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const endpointProfileDetail =
    useMockData == 'true'
      ? wcs.getEndPoint('getProfileDetails')
      : `${domain}/wcs/resources/store/${storeId}/person/@self?_${new Date().getTime()}&langId=${langId}`

  let person = {}
  await axios.get(endpointProfileDetail).then(
    response => {
      person = response
    },
    error => {
      console.error(error)
    }
  )

  return getProfileDetailsNormalized(person)
}

export const getPasswordRequirements = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const endpointPasswordRequirements =
    useMockData == 'true'
      ? wcs.getEndPoint('getPasswordRequirements')
      : `${domain}/wcs/resources/store/${storeId}/password/policy`
  let requirements = {}
  await axios.get(endpointPasswordRequirements).then(
    response => {
      requirements = response
    },
    error => {
      console.error(error)
    }
  )

  return requirements
}

export const getAddressEndpoint = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/person/@self/contact?_${new Date().getTime()}&langId=${langId}`

  let address = {}
  await axios.get(url).then(
    response => {
      address = response
    },
    error => {
      console.error(error)
    }
  )

  return getAddressNormalized(address)
}

export const logoutEndpoint = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const endpointLogout = `${domain}/wcs/resources/store/${storeId}/person/logout?updateCookies=true&langId=${langId}`

  let logout = {}
  await axios.post(endpointLogout).then(
    response => {
      logout = response.data
    },
    error => {
      console.log(error)
      logout.error = true
    }
  )

  return logout
}

export const forgotEndpoint = async dataToSend => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const endpointForgot = `${domain}/wcs/resources/store/${storeId}/account/sendResetUrl?langId=${langId}`
  let reset = {}
  const param = {}
  param.logonId = dataToSend.email
  if (dataToSend.recaptcha) {
    param['g-recaptcha-response'] = dataToSend.recaptcha
  }

  await axios.post(endpointForgot, param).then(response => {
    reset = response.data
  })
  return reset
}

export const changePasswordEndpoint = async p => {
  let passwordChange = {}
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const endpointChangePass = `${domain}/wcs/resources/store/${storeId}/account/password?langId=${langId}`

  await axios.post(endpointChangePass, p).then(
    response => {
      passwordChange = response.data
    },
    error => {
      console.log({ error })
      passwordChange.error =
        error.response && error.response.data.errors.length ? error.response.data.errors : 'Error'
    }
  )
  return passwordChange
}

export const digestMessage = async message => {
  try {
    const msgUint8 = new TextEncoder().encode(message)
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8)
    const hashArray = Array.from(new Uint8Array(hashBuffer))
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('')
    return hashHex.toUpperCase()
  } catch (err) {
    console.error(err)
    throw err
  }
}

export const getOrderReturnHistory = async userId => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const orderReturnHistoryUrl =
    useMockData == 'true'
      ? wcs.getEndPoint('getOrderReturnHistory')
      : `${domain}/wcs/resources/store/${storeId}/ordercst/orderHistory?_${new Date().getTime()}&customerEmail=${encodeURIComponent(
          userId
        )}&langId=${langId}`
  let data = {}

  try {
    data = await axios.get(orderReturnHistoryUrl)
    return getOrderReturnHistoryNormalized(data)
  } catch (error) {
    console.log(error)
    return error.response
  }
}

export const uploadPrescriptionOrderDetail = async params => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const orderReturnHistoryUrl = `${domain}/wcs/resources/store/${storeId}/sgh/prescription/file`
  let data = {}

  await axios.post(orderReturnHistoryUrl, params).then(
    response => {
      data = response.data.prescriptionId
    },
    error => {
      console.log(error)
      data.error = error.message
    }
  )

  return data
}

export const addressZipcodeAutocomplete = async zipcode => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/zipcodeautocomplete/${zipcode}/simple`
  let data = {}

  await axios.get(url).then(
    response => {
      data = response.data.locationDetails
    },
    error => {
      console.log(error)
    }
  )
  return data
}

export const getStateAndZipRegex = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const url = `${domain}/wcs/resources/store/countryselector/countrylist`
  let data = {}

  await axios
    .get(url)
    .then(response => {
      data = response.data
    })
    .catch(() => {
      data.error = true
    })

  return getStateAndZipNormalized(data)
}

export const getOrderLabel = async url => {
  let data = {}

  await axios
    .get(url)
    .then(response => {
      data = response.data
    })
    .catch(() => {
      data.error = true
    })

  return data
}

export const downloadInvoiceFile = async orderId => {
  let data = {}
  const { storeId } = wcs.getStoreConfig()
  const url = `/wcs/resources/store/${storeId}/invoice/${orderId}/downloadInvoiceBySAP`

  await axios
    .get(url, {
      responseType: 'blob',
    })
    .then(response => {
      data = window.URL.createObjectURL(new Blob([response.data]))
    })
    .catch(() => {
      data.error = true
    })

  return data
}

export const getFavouriteStores = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const endpoint =
    useMockData == 'true'
      ? wcs.getEndPoint('getFavouriteStores')
      : `${domain}/wcs/resources/store/${storeId}/favorite/@self?_${new Date().getTime()}&langId=${langId}`

  let data = {}
  await axios.get(endpoint).then(
    response => {
      data = response.data
    },
    error => {
      console.error('Failed call to favourite stores endpoint')
    }
  )

  return getFavouriteStoresNormalized(data)
}
export const toggleFavouriteStores = async payload => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  let data = {}
  const type = payload.favourite ? 'post' : 'delete'
  await axios[type](`${domain}/wcs/resources/${payload.endpoint}`).then(
    response => {
      data = response.data
    },
    error => {
      console.log('Fail response toggle favourite store  endpoint', error)
      data.error = error.message
    }
  )
  return data
}
export const setDefaultStore = async endpoint => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  let data = {}
  await axios.post(`${domain}/wcs/resources/${endpoint}`).then(
    response => {
      data = response.data
    },
    error => {
      console.log('Fail response for default favourite store', error)
      data.error = error.message
    }
  )
  return data
}
export const getAppointmentsBooking = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()

  const useMockData = wcs.getEnvConfig('useMockData') || false

  const endpoint =
    useMockData == 'true'
      ? wcs.getEndPoint('getAppointementsBooking')
      : `${domain}/wcs/resources/store/${storeId}/appointment/all?_${new Date().getTime()}&langId=${langId}`

  let data = {}
  await axios.get(endpoint).then(
    response => {
      data = response.data
    },
    error => {
      console.error('Failed call to Appointment History endpoint')
    }
  )
  return getAppointmentsBookingNormalized(data)
}

export const getUserType = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const endpointUserType = `${domain}/wcs/resources/store/${storeId}/person/type?_${new Date().getTime()}`

  let userType =
    window.constants && window.constants.ajaxParams.userType.length != 0
      ? window.constants.ajaxParams.userType
      : 'G'

  let userId =
    window.constants && window.constants.ajaxParams.userId.length != 0
      ? window.constants.ajaxParams.userId
      : '-1002'

  await axios.get(endpointUserType).then(
    response => {
      if (response.data) {
        if (response.data.registerType && response.data.registerType != userType) {
          userType = response.data.registerType
          window.constants.ajaxParams.userType = userType
        }
        if (response.data.userId && response.data.userId != userId) {
          userId = response.data.userId
          window.constants.ajaxParams.userId = userId
        }
      }
    },
    error => {
      console.error(error)
    }
  )
  const userTypeAnalyticsValue = userType === 'R' ? 'Logged' : 'Guest'

  sessionStorage.setItem('User_LoginStatus', userTypeAnalyticsValue)
  if (userType !== 'R') {
    sessionStorage.removeItem('User_Email_MD5')
  }

  return userType
}

export const getLoyaltyItems = async cardNumber => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()

  const useMockData = wcs.getEnvConfig('useMockData') || false
  const endpoint =
    useMockData === 'true'
      ? wcs.getEndPoint('getLoyaltyProducts')
      : `${domain}/wcs/resources/store/${storeId}/loyalty/benefits/${cardNumber}`
  let data = {}
  await axios.get(endpoint).then(
    response => {
      data = response.data
    },
    error => {
      console.error('Failed call to get benefit from loyalty endpoint')
    }
  )

  return getLoyaltyProductsNormalized(data)
}

export const getLoyaltyStatus = async param => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()

  const useMockData = wcs.getEnvConfig('useMockData') || false

  const endpoint =
    useMockData == 'true'
      ? wcs.getEndPoint('getLoyaltyStatus')
      : `${domain}/wcs/resources/store/${storeId}/loyalty/refresh-by-email?_${new Date().getTime()}&langId=${langId}`

  let data = {}
  await axios.post(endpoint, param).then(
    response => {
      data = response.data
    },
    error => {
      console.log(error)
    }
  )
  return data
}

export const sendLoyaltyPrivacy = async (param, value) => {
  const urlEndpoint = sessionStorage.getItem('loyPrivacyURL')
  const useMockData = wcs.getEnvConfig('useMockData') || false
  const url =
    useMockData == 'true'
      ? wcs.getEndPoint('getLoyaltyStatus')
      : urlEndpoint

  const data = await axios.put(
    url,
    {
      consents: [
        {
          type: 'COMMUNICATION',
          description: '',
          value: value,
        },
      ],
    },
    {
      headers: {
        'X-Loyalty-Language': 'en',
        Authorization: param,
      },
    }
  )
  return data
}

export const restoreLoyaltySubscription = async param => {
  const url = sessionStorage.getItem('loyReactivationURL')

  const data = await axios.post(url, null, {
    headers: {
      'X-Loyalty-Language': 'en',
      Authorization: param,
    },
  })
  return data
}

export const sendVerifyEmail = async param => {
  const { storeId } = wcs.getStoreConfig()
  const url = `/wcs/resources/store/${storeId}/account/verify`

  const data = await axios.post(url, param)
  return data
}

export const loyaltyJoinUser = async properties => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/loyalty/subscribe`
  let data = {}

  await axios.post(url, properties).then(
    response => {
      data = response.data
    },
    error => {
      data.error = error.response && error.response.data && error.response.data.message
      console.log(error)
    }
  )
  return data
}

export function rectifyBirthdate(e, form) {
  const name = e.target.attributes.name.value
  let value = trimCharacters(form[`${name}`])
  const currentYear = (new Date().getFullYear() - 16).toString()
  if (value > 31 && name === 'day') {
    return 31
  } else if (value > 12 && name === 'month') {
    return 12
  } else if ((value > currentYear || value.length > 4) && name === 'year') {
    return currentYear
  } else return value
}

//trims characters in string leaving only digits
function trimCharacters(str) {
  let trimmedStr = ''
  for (let i = 0; i < str.length; i++) {
    if (/^\d$/.test(str.charAt(i))) {
      trimmedStr += str.charAt(i)
    }
  }
  return trimmedStr
}

export const getCurrentCartStateFromServer = async () => {
  //clean storage from old value
  localStorage.setItem('savedCartState', '')

  const { storeId, langId, catalogId } = wcs.getStoreConfig()
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const url = `${domain}/wcs/resources/store/${storeId}/cart/sgh/@self?catalogId=${catalogId}&langId=${langId}&storeId=${storeId}&_=${new Date().getTime()}`
  let data = []

  await axios.get(url).then(
    response => {
      const cartObject = response.data?.orderItems
      if (cartObject) {
        data = response.data.orderItems
      }
    },
    error => {
      data.error = error.response && error.response.data && error.response.data.message
      console.log(error)
    }
  )

  //set new value
  localStorage.setItem('savedCartState', JSON.stringify(data))
  localStorage.setItem('Updated_Cart_After_Loggin', 'true')
  return data
}

const setAddress2DBField = parameters => {
  const { locale } = wcs.getStoreConfig()
  if (locale == 'pt_BR' || locale == 'es_MX') return parameters.municipio
  return parameters.addressApartment
}
