import axios from 'axios'
import wcs from './index'
import { getWishlistNormalized } from '../normalizer'
import { updateCounterWishlist } from '../../libs/utils/counterWishlist'
import qs from 'qs'
import { getCurrentCountry } from '../utils/currentCountry'

export async function wcs_add_wishlist_product({ catentryId }) {
  const { storeId } = wcs.getStoreConfig()
  const newUrl = `/wcs/resources/store/${storeId}/wishlist/sgh/catEntryId/${catentryId}`

  const response = await axios
    .post(newUrl)
    .then(res => res)
    .catch(error => new Error('Failed adding to wishlist', error))
  return response.data
}

export async function wcs_remove_wishlist_product(catentryId) {
  const { storeId } = wcs.getStoreConfig()
  const newUrl = `/wcs/resources/store/${storeId}/wishlist/sgh/catEntryId/${catentryId}`
  const response = await axios
    .delete(newUrl)
    .then(res => res)
    .catch(error => new Error('Failed removing item from wishlist', error))
  return response.data
}

export const getWishlistProducts = async (payload = { sortBy: 1 }) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const useMockData = wcs.getEnvConfig('useMockData') || false
  let params = '?'
  params += payload.sortBy ? `orderBy=${payload.sortBy}&` : ''
  params += payload.listId ? `listId=${payload.listId}&` : ''
  params += `_${new Date().getTime()}`
  const getWishlistEndpoint =
    useMockData == 'true'
      ? wcs.getEndPoint('getWishlistProducts')
      : `${domain}/wcs/resources/store/${storeId}/wishlist/sgh/@self${params}&langId=${langId}`
  let response = {}

  try {
    response = await axios.get(getWishlistEndpoint)
    return getWishlistNormalized(response.data)
  } catch (error) {
    console.log(error)
    return error.response
  }
}
export const postEmailSubscription = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const url = `${domain}/${getCurrentCountry()}/${parameters.brand}/EmailSubscription`
  const params = {
    email: parameters.email,
  }
  let data = {}
  try {
    data = await axios.post(url, qs.stringify(params))
  } catch (error) {
    data.error = 'Error'
  }
  return data
}

export const postEmailMe = async parameters => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const url = `${domain}/wcs/resources/store/${storeId}/emailMe`
  const params = {
    email: parameters.email,
    catEntryId: parameters.catEntryId,
    langId: langId,
  }

  let data = {}

  try {
    data = await axios.post(url, qs.stringify(params))
  } catch (error) {
    /* data.error =
      error.response && error.response.data.errors.length && error.response.data.errors.message
        ? error.response.data.errors.message
        : 'Error' */
    data.error = 'Error'
  }
  return data
}

export const getCounterWishlist = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId, langId } = wcs.getStoreConfig()
  const endpointCounterWishlist = `${domain}/wcs/resources/store/${storeId}/wishlist/sgh/count?time=${new Date().getTime()}&langId=${langId}`

  let countItemWishlist = {}
  await axios.get(endpointCounterWishlist).then(
    response => {
      countItemWishlist = response.data.totalProducts
      updateCounterWishlist(countItemWishlist)
    },
    error => {
      console.log(error)
      countItemWishlist.error =
        error.response &&
        error.response.data.errors.length &&
        error.response.data.errors[0].errorMessage
          ? error.response.data.errors[0].errorMessage
          : 'Error'
    }
  )
  return countItemWishlist
}

export const addToBagCustomized = async (url, params) => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const endpoint = `${domain}/wcs/resources${url}`

  let data = {}

  try {
    data = await axios.post(endpoint, params)
  } catch (error) {
    data.error = 'Error'
  }
  return data
}

export const getWishlistProductsCatentryArray = async () => {
  const domain = wcs.getEnvConfig('currentDomain') || ''
  const { storeId } = wcs.getStoreConfig()
  const urlAddr = `${domain}/wcs/resources/store/${storeId}/wishlist/sgh/list`

  try {
    let response = await axios.get(urlAddr)
    return response.data[0].catEntryId
  } catch (error) {
    console.error(error)
    return error.response
  }
}
